<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <tabel_data :rs_data=rs_data @DetailData="DetailData" @DeleteBalaiBidang="DeleteBalaiBidang"></tabel_data>
        </b-card>
      </b-col>

    </b-row>
    <tambah-user :rs_detail=rs_detail @InsertData=InsertData :rs_user="rs_user"></tambah-user>

  </div>
</template> 

<script>
import axios from '@/config/Axios';
import Base from '@/config/Mixins_base';
import tabel_data from './component/tabel.vue';
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import TambahUser from './component/TambahUser.vue';
export default {
  components: {
    BCard, tabel_data,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BButton,
    TambahUser
  },
  data() {
    return {
      rs_data: [],
      rs_detail: {},
      rs_user: []
    }

  },
  mixins: [Base],
  computed: {},
  mounted() {
    this.laod_data();
    this.load_user();
  },
  methods: {
    DetailData(val) {
      this.rs_detail = val
    },
    async laod_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/admin/laod_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_user() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/admin/load_user',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_user = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },


    async DeleteBalaiBidang(id) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/setup/admin/delete',
        data: {
          id: id
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.laod_data()
          self.load_user();
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async InsertData(user_key, id_bidang) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/setup/admin/insert_admin',
        data: {
          user_key: user_key, id_bidang: id_bidang
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.laod_data()
          self.load_user();
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
}
</script>