<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-1" :title="rs_detail.nama_balai" ok-disabled size="lg" hide-footer>
      <b-row>
        <b-col md="12">
          <b-form-group label="Select User unt dijadkan admin Balai Bidang">
            <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nm_lengkap"
              @input="InsertData(selected.user_key, rs_detail.id_bidang)" placeholder="Pilih wiyayah Balai Bidang"
              :options="rs_user" />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <!-- <ul>
        <li v-for="rs in rs_detail.listbalai" v-bind:key="rs">
          <b-row>
            <b-col md="5">
              {{ rs.nm_wil }}
            </b-col>
            <b-col md="1">
              <b-badge variant="danger"><feather-icon icon="XIcon" size='15' /></b-badge>
            </b-col>
          </b-row>
        </li>
      </ul> -->
    </b-modal>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BCol, BRow, BFormGroup, BCard, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton, BCard, BBadge,
    BModal, vSelect, BFormGroup,
    BAlert, BCol, BRow,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected: {}
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["InsertData"],
  methods: {
    InsertData(user_key, id_bidang) {
      this.$emit('InsertData', user_key, id_bidang)
      this.selected = {}
    },
    close() {
      this.$root.$emit('bv::hide::modal-1', 'AddLevel')
    }
  },
  props: {
    rs_user: {},
    rs_detail: {}
  },
  computed: {
    // items: {
    //   get: function () {
    //     return this.rs_detail;
    //   },
    //   set: function (newValue) {
    //     return this.rs_detail = newValue;
    //   }
    // },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>